import React from "react";
import { FaCog } from "react-icons/fa";

export interface LogoProps {}

const Logo: React.FC<LogoProps> = (props: LogoProps) => {

	return (
		<div id="logo-container" className="flex items-center">
			<FaCog id="logo-logo" className="text-white" size={16} />
			<span id="logo-title" className="text-white pl-2">
				<span id="logo-text">Design Shift</span>
			</span>
		</div>
	);

};

export default Logo;