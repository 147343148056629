import React from "react";
import { RiRocketLine, RiShieldLine, RiCalendarLine, RiFileLine } from "react-icons/ri";

export interface IndexHelperCardProps {

	title: string;
	description: string;
	children: React.ReactNode;

}

const IndexHelperCard: React.FC<IndexHelperCardProps> = (props: IndexHelperCardProps) => {

	return (
		<div id="indexhelpercard-card" className="text-white p-4 rounded-lg shadow-md text-start items-start h-96 w-36 w-full rounded-3xl mx-auto flex items-center">
			<div id="indexhelpercard-content">
				<p className="pb-8">{props.children}</p>
				<p id="indexhelpercard-text-AAAAA" className="text-2xl font-bold pb-3">
					{props.title}
				</p>
				<p id="indexhelpercard-get-started">{props.description}</p>
			</div>
		</div>
	);

};

export default IndexHelperCard;