import React from "react";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { TbBrandX } from "react-icons/tb";
import { useIndexState } from "./../Index";

export interface IndexFooterProps {}

const IndexFooter: React.FC<IndexFooterProps> = (props: IndexFooterProps) => {

	let { hasDemoVideo } = useIndexState();

	return (
		<footer id="indexfooter-footer" className="bg-gray-800 text-white p-8 mt-4">
			<div id="indexfooter-content-container" className="max-w-7xl mx-auto grid md:grid-cols-4 grid-cols-2 gap-8">
				<div id="indexfooter-legal-info" className="col-span-1 text-center">
					<div id="indexfooter-legal-title" className="font-semibold">
						Learning
					</div>
					<ul id="indexfooter-legal-menu" className="cursor-pointer">
						<li id="indexfooter-legal-contact" className="cursor-pointer">
							<a href="faq.html">FAQ</a>
						</li>
						{hasDemoVideo && (
							<li id="indexfooter-legal-terms" className="cursor-pointer">
								Demo Video
							</li>
						)}
					</ul>
				</div>
				<div id="indexfooter-legal-info" className="col-span-1 text-center">
					<div id="indexfooter-legal-title" className="font-semibold">
						Legal
					</div>
					<ul id="indexfooter-legal-menu" className="cursor-pointer">
						<li id="indexfooter-legal-terms" className="cursor-pointer">
							<a href="termsofservice.html">Terms of Service</a>
						</li>
						<li id="indexfooter-legal-contact" className="cursor-pointer">
							<a href="privacypolicy.html">Privacy Policy</a>
						</li>
					</ul>
				</div>
				<div id="indexfooter-support-info" className="col-span-1 text-center">
					<div id="indexfooter-support-title">Support</div>
					<ul id="indexfooter-support-menu" className="cursor-pointer">
						<li id="indexfooter-support-delivery" className="cursor-pointer">
							<a href="https://forms.office.com/r/fua6YdiNjq">Contact Us</a>
						</li>
						<li id="indexfooter-support-returns" className="cursor-pointer">
							<a href="https://combinedplatforms.atlassian.net/servicedesk/customer/portal/2">Support</a>
						</li>
					</ul>
				</div>
				<div id="indexfooter-connect-info" className="col-span-1 text-center">
					<div id="indexfooter-connect-title">Presented By</div>
					<ul id="indexfooter-connect-menu" className="flex justify-center space-x-4 mt-2 cursor-pointer">
						<img src="images/combinedplatforms.png" width="184" height="25" />
					</ul>
				</div>
			</div>
			<div id="indexfooter-footer-bottom" className="mt-8 text-center text-sm text-gray-500">
				Copyright © Combined Platforms 2024
			</div>
		</footer>
	);

};

export default IndexFooter;