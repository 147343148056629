import React from "react";
import { RiComputerLine } from "react-icons/ri";
import { MdSettings } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiFillRobot } from "react-icons/ai";
import IndexHelperCard from "./IndexHelperCard";
import { useIndexState } from "./../Index";

export interface IndexHelperCardsProps {}

const IndexHelperCards: React.FC<IndexHelperCardsProps> = (props: IndexHelperCardsProps) => {

	const { hasFigmaSupport } = useIndexState();

	return (
		<div id="indexhelpercards-main-container" className="grid grid-cols-1 md:grid-cols-4 gap-16 mt-4 w-full pt-12 pb-16">
			<IndexHelperCard title="AI Driven" description="Driven by industry standard GPT-4o models. Use your existing ChatGPT knowledge.">
				<AiFillRobot size={48} />
			</IndexHelperCard>
			<IndexHelperCard title={hasFigmaSupport ? "Vendor Support" : "Fast Development"} description={`Supports SVG Exports from ${hasFigmaSupport ? "the market-leading design tools you use, including Figma and Uizard" : "Uizard, fast-tracking designs through the developer desk"}.`}>
				<RiComputerLine size={48} />
			</IndexHelperCard>
			<IndexHelperCard title="Highly Adaptable" description="You control the conversion scripts, generate components to your standards.">
				<MdSettings size={48} />
			</IndexHelperCard>
			<IndexHelperCard title="Simple Pricing" description="Simple pricing structure gives you full clarity on costs. No hidden charges.">
				<FaMoneyBillWave size={48} />
			</IndexHelperCard>
		</div>
	);

};

export default IndexHelperCards;